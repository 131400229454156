import "./Home.css";
import Button from "../../components/common/button/Button";
import "../../css/mobile.css"
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import useGetCases from "../../hooks/useGetCases";
import React from "react";
import { Loader } from "../../components/loader/Loader";
import FreeConsultation from "../../components/free-consultation/FreeConsultation";

const Home = () => {

    const navigateOnWebsite = useNavigate();
    const { cases, loading, error } = useGetCases();
    const [showConsultation, setShowConsultation] = React.useState(false);

    return (
        <>
            <Helmet>
                <title>Home | JKM Solutions</title>
                <meta name="description" content="We architect, develop, test, deploy & maintain full-stack web applications in the gaming niche, often utilizing the Steam API as a part of the core functionality." />
                <link rel="canonical" href="https://jkm.solutions" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Home | JKM Solutions" />
                <meta property="og:description" content="We architect, develop, test, deploy & maintain full-stack web applications in the gaming niche, often utilizing the Steam API as a part of the core functionality." />
                <meta property="og:url" content="https://jkm.solutions" />
                <meta property="twitter:title" content="Home | JKM Solutions" />
                <meta property="twitter:description" content="We architect, develop, test, deploy & maintain full-stack web applications in the gaming niche, often utilizing the Steam API as a part of the core functionality." />
                <meta property="twitter:url" content="https://jkm.solutions" />
            </Helmet>
            <main>
                {showConsultation && <FreeConsultation showConsultation={showConsultation} setShowConsultation={setShowConsultation} />}
                <section className="hero">
                    <div className="container">
                        <div className="d-flex row">
                            <div className="col-lg-6 side mb-3 left-side">
                                <h1 className="title" id="title">
                                    We build Steam API applications, completely done for you.
                                </h1>
                                <h2 className="second-title">
                                    {/* We love challenging projects.
                                With experience in everything from Stripe payments and Steam development to Discord bots and Google Maps,
                                we can help bring your idea to life. */}
                                    We architect, develop, test, deploy & maintain full-stack web applications in the gaming niche, often utilizing the Steam API as a part of the core functionality.
                                </h2>
                                <Button text="Get in touch" onClick={() => setShowConsultation(true)} />
                            </div>
                            <div className="col-lg-6 side align-items-center">
                                <img src="/img/front.png" alt="code symbol in pink" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="services" id="services">
                    <div className="container">
                        <div className="row d-flex mb-4">
                            <div className="mb-5 mt-2">
                                <h2 className="subtitle dark-blue">Our Services</h2>
                            </div>
                            <div className="col-lg-4 d-flex">
                                <Link className="card align-self-stretch" to="/contact">
                                    <img src="/img/icons/steam.png" height="150px" width="150px"
                                        alt="Icon showing steam logo"
                                        className="p-4" />
                                    <h3>
                                        Steam API
                                    </h3>
                                    <p className="cardText mb-4">
                                        We have extensive experience with the Steam API and can help you integrate it into your website. We build login systems, Steam bots, inventory fetching solutions, price scrapers, and more.
                                    </p>
                                    <Link to="/contact">Get in touch</Link>
                                </Link>
                            </div>
                            <div className="col-lg-4 d-flex">
                                <Link className="card align-self-stretch" to="/cases">
                                    <img src="/img/icons/webdev.png" height="150px" width="150px"
                                        alt="Icon showing web development"
                                        className="p-2" />
                                    <h3>
                                        Gambling websites
                                    </h3>
                                    <p className="cardText mb-4">
                                        We build, deploy, test, and maintain online casinos geared towards Steam games & crypto, with game modes like Plinko, Crash, Roulette, Coinflip, Case Battles, Mines, Dice, and more.
                                    </p>
                                    <Link to="/cases">See Portfolio</Link>
                                </Link>
                            </div>
                            <div className="col-lg-4 d-flex">
                                <Link className="card align-self-stretch" to="/cases">
                                    <img src="/img/icons/api.png" height="150px" width="150px"
                                        alt="Icon showing API with cog wheel"
                                        className="p-3" />
                                    <h3>
                                        API Integrations
                                    </h3>
                                    <p className="cardText mb-4">
                                        We can help you integrate any API into your website.
                                        We have worked with everything from payments (Stripe, Binance, Coinbase)
                                        to map providers (Mapbox, Google), and are not afraid to tackle new ones.
                                    </p>
                                    <Link to="/contact">Live examples</Link>
                                </Link>
                            </div>
                        </div>
                        <br />
                        <Button text="See all services" onClick={() => navigateOnWebsite('/services')} />
                        <br />
                        <br />
                    </div>
                </section>

                <section className="figures pt-4 pb-4">
                    <div className="container">
                        <div className="figures-wrap">
                            <div className="figure">
                                <h2 className="figure-title">45+</h2>
                                <p>finished projects</p>
                            </div>
                            <div className="figure">
                                <h2 className="figure-title">25</h2>
                                <p>global clients</p>
                            </div>
                            <div className="figure">
                                <h2 className="figure-title">6444</h2>
                                <Link to="https://github.com/kevalane" target="_BLANK">github commits (last 12 months)</Link>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="portfolio pb-5" id="portfolio">
                    <div className="container">
                        <div className="row d-flex portfolio-row">
                            <div className="mt-4">
                                <h2 className="left-title">Select works</h2>
                            </div>

                            {loading && <Loader />}
                            {error && <p className="text-danger">{error}</p>}

                            {cases && cases.slice(0, 3).map((caseItem, i) => (
                                <div className={`row d-flex mt-lg-3 portfolio-item ${i === 1 ? 'middle-item' : ''}`} key={i}>
                                    <div className="col-lg-6">
                                        <img src={process.env.REACT_APP_API_URL + caseItem.attributes.image.data.attributes.url}
                                            className="portfolio-img"
                                            alt={`Showcasing ${caseItem.attributes.title}`}
                                            onClick={() => window.open(caseItem.attributes.url, '_blank')} />
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="portfolio-text">
                                            <h3 className="portfolio-title">{caseItem.attributes.title}</h3>
                                            <p className="portfolio-description">{caseItem.attributes.intro}</p>
                                            <a href={caseItem.attributes.url} target="_blank" rel="noreferrer">Visit {caseItem.attributes.url}</a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <p className="text-center mt-5">
                                <Button text="See all cases" onClick={() => navigateOnWebsite('cases')} />
                            </p>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Home;
import React from 'react';
import { FaArrowRight } from 'react-icons/fa';

import './FirstStep.css';

const FirstStep = ({setShowConsultation}) => {
    return (
        <div className="first-step pb-3">
            <div className="container pb-5">
                <div className="first-wrap">
                    <div>
                        <h2>Ready to take the first step?</h2>
                        <p className="text-light intro-text pb-3">
                            We are always looking for new and exciting projects to work on. 
                            If you have an idea you want to bring to life, or need help with an existing project, 
                            contact us today for a free consultation.
                        </p>
                        <ul>
                            <li><FaArrowRight color="ff99bf"/> 30 minute call (or text!)</li>
                            <li><FaArrowRight color="ff99bf"/> Find out what is possible</li>
                            <li><FaArrowRight color="ff99bf"/> Get a quote</li>
                            <li><FaArrowRight color="ff99bf"/> Of course it is free!</li>
                        </ul>
                        <p className="btn-wrap">
                        <button className="btn" onClick={() => setShowConsultation(true)}>Book a free consultation</button>
                        </p>
                    </div>
                    <div className="code-img-wrap">
                        <img src="/img/front.png" alt="code symbol in pink"
                            width="365" height="221"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FirstStep;

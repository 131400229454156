import { useState, useEffect } from 'react';
import axios from 'axios';

const useGetCases = () => {
	const [cases, setCases] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(process.env.REACT_APP_API_URL + '/api/cases?populate=*', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `bearer ${process.env.REACT_APP_STRAPI_KEY}`
                    }
                }); 
				let data = response.data.data;
				data.sort((a, b) => {
					return a.attributes.rank - b.attributes.rank;
				})
				setCases(data);
				setLoading(false);
			} catch (err) {
                if (err.response && err.response.data?.message) {
                    setError(err.response.data.message);
                } else {
                    setError('Something went wrong fetching cases. Please try again later.');
                }
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	return { cases, loading, error };
};

export default useGetCases;
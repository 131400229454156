import { useState } from 'react';
import axios from 'axios';

export const useSendJKMEmail = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const sendEmail = async (emailData) => {
        setLoading(true);
        setError('');
        setSuccess('');

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_API_URL}/v1/email/sendJKM`, emailData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
            });

            setLoading(false);

            if (response.status === 200) {
                setSuccess(response.data.message || 'Email sent successfully. We will be in touch soon!');
                return true;
            } else {
                setError('Failed to send email. Please try again later.');
                return false;
            }
        } catch (err) {
            setLoading(false);
            setError(err.response?.data?.message || 'An error occurred while sending the email. Please try again later.');
            return false;
        }
    };

    return { sendEmail, loading, error, success, setError, setSuccess };
};
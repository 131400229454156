import React from 'react';
import { Helmet } from 'react-helmet';
import useGetCases from '../../../hooks/useGetCases';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { Loader } from '../../../components/loader/Loader';
import FirstStep from '../../../components/first-step/FirstStep';
import FreeConsultation from '../../../components/free-consultation/FreeConsultation';

import '../../cases/Cases.css';

import './Steam.css';

const Steam = () => {
    const { cases, loading, error } = useGetCases();
    const [showConsultation, setShowConsultation] = React.useState(false);

    React.useEffect(() => {
        if (showConsultation) {
            document.body.classList.add('body-shadow');
        } else {
            document.body.classList.remove('body-shadow');
        }
    }, [showConsultation]);

    return (
        <>
            <Helmet>
                <title>Steam API Development | JKM Solutions</title>
                <meta name="description" content="We have extensive experience with the Steam API and can help you integrate it into your website. We build login systems, Steam bots, inventory fetching solutions, price scrapers, and more." />
                <link rel="canonical" href="https://jkm.solutions/services/steam" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Steam API Development" />
                <meta property="og:description" content="We have extensive experience with the Steam API and can help you integrate it into your website. We build login systems, Steam bots, inventory fetching solutions, price scrapers, and more." />
                <meta property="og:url" content="https://jkm.solutions/services/steam" />
                <meta property="twitter:title" content="Steam API Development" />
                <meta property="twitter:description" content="We have extensive experience with the Steam API and can help you integrate it into your website. We build login systems, Steam bots, inventory fetching solutions, price scrapers, and more." />
                <meta property="twitter:url" content="https://jkm.solutions/services/steam" />
            </Helmet>
            <section className={`steam-wrap`}>
                {showConsultation && <FreeConsultation showConsultation={showConsultation} setShowConsultation={setShowConsultation} />}
                <div className="container">
                    <div className="header">
                        <div>
                            <h1 className="title">Steam API development</h1>
                            <p className="text-light intro-text">
                                With the growing ecosystem around steam games, building a website that integrates with Steam can be a great way to grow your business.
                                <br /><br />
                                With our extensive experience with the Steam API, we can help you build a wide range of applications, including: "sign in though steam" systems, 
                                inventory fetching solutions, trading websites & bots, price scrapers, and more.
                                <br /><br />
                            </p>
                            <p className="btn-wrap">
                                <button className="btn" onClick={() => setShowConsultation(true)}>Get in touch</button>
                            </p>
                        </div>
                        <img src="/img/icons/steam.png" alt="Steam API Development" 
                            className="steam-img" height="200" width="200"/>
                    </div>

                    <div className="cases row">
                        <h3>Some Steam API projects we have done</h3>
                        {loading && <Loader />}
                        {error && <p className="text-danger">{error}</p>}

                        {cases && cases
                            .filter(c => ["CSAngelic LLC", "Rustywager", "Gecko Capital AS"].includes(c.attributes.company))
                            .map((c, i) => (
                            <div className="col-md-6 col-xl-4 d-flex mt-2" key={i}>
                                <div className={`card ${i === 0 ? 'fly-left' : ''}
                                                      ${i === 1 ? 'fly-bottom' : ''}
                                                      ${i === 2 ? 'fly-right' : ''}`}>
                                    <img src={process.env.REACT_APP_API_URL + c.attributes.image.data.attributes.formats.large.url} className="card-img-top" alt={c.attributes.title} />
                                    <div className="card-body">
                                        <p className="technologies">{c.attributes.job_type}</p>
                                        <h5 className="card-title">{c.attributes.title}</h5>
                                        <p className="card-text">{c.attributes.intro}</p>
                                        <div className="link-wrap pb-3">
                                            <a href={c.attributes.url} target="_blank" rel="noreferrer" className="visit-link">
                                                Visit {c.attributes.url}
                                                <span className="link-icon">
                                                <FaExternalLinkAlt />
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <article className="content">
                        <h4>Free consultation</h4>
                        <p>
                            We realize that it can be difficult navigate the jungle of what's possible with the Steam API, 
                            and that's why we offer a free consultation where we can discuss your needs and
                            find the best solution for you. This consult can be done over the phone, on Discord, or any 
                            text messaging platform of your choice.
                        </p>
                    </article>

                    <article className="content">
                        <h4>Example projects</h4>
                        <p>
                            Websites that integrate with CS2 skin-trading are the most common projects we do. More specifically, we have experience
                            with P2P trading websites, skins-to-cash websites, skin price scrapers, and inventory fetching solutions. We have also done
                            Rust (adding features to an already built casino website) and TF2 projects (both a coinflip website and a keys-to-crypto 
                            bot allowing users to exchange TF2 keys for crypto).
                        </p>
                    </article>

                    <article className="content">
                        <h4>Done-for-you</h4>
                        <p>We take care of everything (research, design, development, deployment and maintenance), so you can focus on growing your business.
                            We always send you weekly updates on the progress of our project, so you can be sure that everything is going according to plan and 
                            request any changes you want.
                        </p>
                    </article>

                    <article className="content">
                        <h4>Other integrations</h4>
                        <p>
                            Steam API websites typically require other integrations as well, such as Stripe/PayPal payments and cryptocurrency functionality (Plisio 
                            payment gateways, Coinbase/Binance wallet API:s). We have experience with all of these integrations and can help you with them as well.
                        </p>
                    </article>
                    <br /><br /><br /><br />
                </div>
                <FirstStep setShowConsultation={setShowConsultation}/>
            </section>
        </>
    );
}

export default Steam;
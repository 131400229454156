import React, {useEffect, useState} from "react";
import { Link, useNavigate,useLocation } from "react-router-dom";

// Import styles
import "./Navbar.css";

export default function Navbar(){

    const [isNavbarOpen, setIsNavbarOpen] = useState(false);
    const location = useLocation().pathname;

    const isActive = (path) => {
        return location === path ? 'active' : '';
    };

    const navigate = useNavigate();

    const toggleNavbar = () => {
        setIsNavbarOpen(!isNavbarOpen);
    };

    const closeNavbar = () => {
        setIsNavbarOpen(false);
    };

    useEffect(() => {
        console.log(isNavbarOpen)
        if (isNavbarOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
    }, [isNavbarOpen])

    const barClass = isNavbarOpen ? 'active' : '';

    return (
        <header className="nav-wrap">
            <nav className="navbar navbar-expand-lg">
                <div className="container">
                    {/* Logo */}
                    <Link className="navbar-brand" to="/" onClick={closeNavbar}>
                        <img src="/img/logo.png" alt="JKM Solutions Logo" 
                             height="50"/>
                    </Link>

                    {/* Button toggler for mobile */}
                    <div className="burger-box" onClick={() => toggleNavbar()}>
                        <div className={`bar bar1 ${barClass}`}></div>
                        <div className={`bar bar2 ${barClass}`}></div>
                        <div className={`bar bar3 ${barClass}`}></div>
                    </div>

                    <div className={`collapse navbar-collapse ${isNavbarOpen ? 'show' : ''}`}
                         id="navbarSupportedContent">
                        <ul className={`navbar-nav ms-auto mb-2 mb-lg-0`}>
                            <li className={`nav-item`}>
                                <a href="https://jkm.solutions/blog/" className={`nav-link ${isActive('/blog')}`} onClick={closeNavbar}>
                                    Blog
                                </a>
                            </li>
                            <li className={`nav-item ${isActive('/cases')}`}>
                                <Link className={`nav-link ${isActive('/cases')}`} onClick={closeNavbar}
                                    to="/cases">
                                    Cases
                                </Link>
                            </li>
                            <li className={`nav-item ${isActive('/services')}`}>
                                <Link className={`nav-link ${isActive('/services')}`} onClick={closeNavbar}
                                    to="/services">
                                    Services
                                </Link>
                            </li>
                            <li className="nav-item">
                                <button className="btn contact-button" onClick={() => {navigate('contact');closeNavbar()}}>Contact</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    )
}